import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {Container, Row} from 'react-bootstrap';
import {useLocation} from '@reach/router';
import {usePageContent} from 'src/functionality/content-provider';
import {theme} from 'src/styles/theme';
import Hero from 'src/images/placeholder.jpg';

import Page from 'src/components/functional/page';
import Tile from 'src/components/functional/tile';
import PlainHeroSection from 'src/components/functional/hero-section-plain';
import {useResources, getResource} from 'src/functionality/resources-provider';
import loading from 'images/loading.gif';
import styled from 'styled-components';

import 'styles/resources.scss';
import Spacer from 'src/components/graphical/spacer';

const LoadingSpinner = styled.img`
  width: 8em;
  display: block;
  margin: auto;
`;

const Category = styled.button`
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
  background-color: ${(props) => !props.selected ? theme.white : theme.primary};
  border: 2px solid ${(props) => !props.selected ? theme.primary : theme.white};
  color: ${(props) => !props.selected ? 'primary' : 'white'};
  border-radius: 500px;
  padding: 0.25em 1em;
  margin: 0.1em 0.25em;
  &:hover{
    background-color: ${(props) =>
      !props.selected ? theme.xlight : theme.darkPrimary};
  }
  &:active{
    border: 2px solid black
  }
`;

const Categories = styled.div`
  margin: 1em 0;
`;

const ResourcesPage = ({pageContext}) => {
  const location = useLocation();
  const content = usePageContent(location.pathname, pageContext.content);
  const [resources, categories] = useResources();
  const [selectedCategory, setSelectedCategory] = useState();

  useEffect(() => {
    if (categories && !selectedCategory) {
      const hashCategory = window.location.hash.substring(1);
      if (hashCategory) {
        setSelectedCategory(hashCategory);
      }
    }
  }, [categories, selectedCategory]);

  return (
    <Page white={true}>
      <PlainHeroSection
        breadcrumbs = {true}
        bgImage={Hero}
        content={content}
        tag="DR01"
        backgroundColor={theme.mediumGray}
        headingSize="md"
      />
      <Container>
        <Categories>
          {categories?.map((category, key) =>(
            <Category
              onClick={(props) => {
                setSelectedCategory(
                    category === selectedCategory ? undefined : category);
              }}
              key={key}
              selected={category === selectedCategory }
            >
              {category.replace(/-/g, ' ')}
            </Category>
          ))
          }
        </Categories>
        {!resources &&
          <LoadingSpinner src={loading} alt="Loading..." />
        }
        <Row>
          {resources?.map((resource, key) =>{
            if (selectedCategory === resource.category) {
              return (
                <Tile
                  isRounded={true}
                  type='document'
                  onClick={() => getResource(resource.url)
                      .then((url) => window.open(url))}
                  key={key}
                  title={resource.title}
                >
                </Tile>);
            }
          },
          )}
        </Row>
        <Spacer height={8}/>
      </Container>
    </Page>
  );
};

ResourcesPage.propTypes = {
  pageContext: PropTypes.shape({
    content: PropTypes.any,
  }),
};

export default ResourcesPage;

